<template>
  <div class="order-detail">
     <van-nav-bar
      left-arrow
      left-text="返回"
      title="我的订单"
      @click-left="onLeft"
    />
    <div class="orderlist-wrap">
      <div @click="jumpDetail(pro)"   v-for="(pro, index) in list.childProduct" :key="index">
         <order-item
            :product="pro"
          />
      </div>
     
      <div class="total">
        <div class="total-con">
          <span class="txt">共<span class="quantity">{{list.childProduct?getProductNum(list.childProduct):''}}</span>件商品</span>
          <!-- <span class="price">已优惠<span>￥{{ 0 }}</span></span> -->
        </div>
      </div>
    </div>
    <div class="order-detail-desc">
      <h1>订单信息</h1>
      <div class="order-detail-desc-info">
        <div class="order-detail-desc-info-item">
          <span class="tit">购买账户：</span>
          <span class="txt">{{list.account}}</span>
        </div>
        <div class="order-detail-desc-info-item">
          <span class="tit">订单编号：</span>
          <span class="txt">{{list.orderId}}</span>
        </div>
        <div class="order-detail-desc-info-item" v-if="list.payStatus == 1">
          <span class="tit">创建时间：</span>
          <span class="txt">{{formatTime(list.createTime)}}</span>
        </div>
        <div class="order-detail-desc-info-item" v-if="list.payStatus == 2">
          <span class="tit">支付时间：</span>
          <span class="txt">{{formatTime(list.payTime)}}</span>
        </div>
        <!-- <div class="order-detail-desc-info-item">
          <span class="tit">优惠码：</span>
          <span class="txt">158965854hh</span>
        </div> -->
      </div>
    </div>
    <div class="order-detail-total">
      <span class="txt">合计：<span class="num">￥{{list.price}}</span></span>
    </div>
  </div>
</template>

<script>
import OrderItem from "./OrderItem.vue";
import { getOrderInfo } from "@api/user.js";
export default {
  data(){
    return {
      //订单详情数据
      list : {}
    }
  },
  mounted(){
     window.onLeft = this.onLeft
  },
  methods : {
    formatTime(t){
      let d = new Date(t);
      console.log(d)
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let date = d.getDate();
      let h = d.getHours < 10 ? "0" + d.getHours() : d.getHours();
      let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      let s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      return `${y}-${m}-${date} ${h}:${min}:${s}`;
    },
    jumpDetail(item){
      this.$router.push({
        name:'details',
        query:{
          id:item.productId
        }
      })
    },
    //返回
    onLeft(){
      this.$router.push({
        name:"order",
        query:{
          tabId:this.$route.query.tabType
        }
      });
    },
    //获取订单详情
    findOrder(){
      const params = {
        orderId : this.$route.query.oid,
        payStatus : this.$route.query.payStatus
      }
      getOrderInfo(JSON.stringify(params))
      .then(res => {
        if(res.code == 0){
          this.list = res.data[0];
        }
      })
    },
    //计算商品总数
    getProductNum(products){
      let count = 0;
      products.forEach(product => {
        count += product.productNum;
      });
      return count;
    },
  },
  components : {
    OrderItem
  },
  created(){
    this.findOrder();
    window.onLeft = this.onLeft
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__content {
  height: 57px;
}
::v-deep .van-icon {
  color: #333333;
}
::v-deep .van-nav-bar__text {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 3px;
}
.order-detail{
  font-family: PingFang SC;
  .orderlist-wrap{
    padding:0 20px;
    .total {
      display: flex;
      flex-direction:row-reverse;
      align-items: center;
      height:40px;
      &-con{
        display: flex;
        align-items: center;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #2f3135;
      }
      .txt {
        margin-right: 5px;
      }
      .price > span {
        margin-left: 2px;
        color: #eb4924;
      }
    }
  }
  &-desc{
    height:183px;
    padding:0 15px;
    border-top:10px solid #F8F8F8;
    h1{
      position:relative;
      padding:13px 0 11px 7px;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      border-bottom: 1px solid #EEEEEE;
      &::before{
        content:"";
        position:absolute;
        display:block;
        left:0;
        top:16px;
        width:2px;
        height:15px;
        background: #FB3D3D;
      }
    }
    &-info{
      height:calc(100% - 45px);
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      padding:15px 0;
      &-item{
        display:flex;
        justify-content:space-between;
        .tit{
          width:110px;
          padding-left:7px;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
        }
        .txt{
          flex:1;
          font-size: 12px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }
  &-total{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    height:109px;
    border-top:10px solid #eee;
    text-align:right;
    .txt{
      margin-right:23px;
      font-size: 15px;
      font-weight: 400;
      color: #2F3135;
      opacity: 0.8;
      .num{
        color:#EB4924;
      }
    }
  }
}
</style>